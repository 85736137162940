import React, { useState, useRef, useEffect } from "react";
import Tesseract from "tesseract.js";
import Webcam from "react-webcam";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "./Loading";
import ml5 from "ml5";
import Loader from "react-loader-spinner";
import useInterval from "@use-it/interval";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReplayCircleFilledSharpIcon from "@mui/icons-material/ReplayCircleFilledSharp";
import DocumentScannerSharpIcon from "@mui/icons-material/DocumentScannerSharp";
import ModelName from "../ModelName";
import CleanlinessModel from "../CleanlinessModel";
import OcrScreen from "./OcrScreen";
import TextScan from "./TextScan";
import { useNavigate } from "react-router-dom";

let classifier;

const ScanComp = () => {
  let navigate = useNavigate();
  const [backdropVisible, setBackdropVisible] = useState(false);
  const videoRef = useRef();
  const [start, setStart] = useState(true);
  const [result, setResult] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [latLong, setLatLong] = useState("");
  const [address, setAddress] = useState("");
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [ocr, setOCR] = useState(false);
  const [modelName, setModelName] = useState("");

  const dimensions = {
    width: 400,
    height: 500,
  };

  const canvasRef = useRef();
  useEffect(() => {
    classifier = ml5.imageClassifier("./model/model.json", () => {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" }, audio: false })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setLoaded(true);
        });
      canvasRef.current.width = 400;
      canvasRef.current.height = 500;
    });
  }, []);

  useEffect(() => {
    getGeoCordinates();
  }, []);

  useInterval(() => {
    if (classifier && start) {
      classifier.classify(videoRef.current, (error, results) => {
        const { width, height } = dimensions;
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, width, height);
        if (error) {
          console.error(error);
          return;
        }
        results.forEach((detection) => {
          ctx.beginPath();
          ctx.fillStyle = "red";
          const { label, x, y, width, height } = detection;
          //console.log("detection...", detection);
          ctx.fillText(detection.label, x, y - 5);
          ctx.rect(x, y, width, height);
          ctx.stroke();
        });
        setResult(results);
        // ]console.log(results)
      });
    }
  }, 500);

  const getGeoCordinates = async () => {
    try {
      if (navigator.geolocation) {
        const options = {
          enableHighAccuracy: true, // Request high accuracy
          timeout: 10000, // Maximum time to wait for a location (5 seconds)
          maximumAge: 0, // Maximum age of a cached location (no cache)
        };

        const successCallback = (position) => {
          const { coords } = position;
          setLatLong(`${coords.latitude}, ${coords.longitude}`);
          getAddress(coords.latitude, coords.longitude);
        };

        const errorCallback = (error) => {
          console.error("Error obtaining location:", error.message);
        };

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
          options
        );  
      } else {
        console.error("Geolocation is not supported in this browser.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAddress = async (lat, long) => {
    try {
      var url = `https://rtkexpo.wegosecure.com/apirtk/location?lat=${lat}&lng=${long}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (response) {
        // const json = await response.json();
        console.log("Response code ===>", json);
        setAddress(json.response.body.address ?? "NaN");
      } else {
        if (address !== "") {
          setAddress("NaN");
        }
      }
      //setAddress("Balaraman Street, Adyar, Chennai");
    } catch (error) {
      console.log("Response code ===>", error);
    }
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100wh",
          maxHeight: "100vh",
          height: "100vh",
        }}
      >
        {backdropVisible && <Backdrop open={true} />}

        <Grid
          container
          sx={{
            height: windowWidth.current,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10%",
          }}
        >
          <Grid item sm={12} xs={12} md={12}>
            <video
              playsInline={true}
              ref={videoRef}
              style={{ transform: "scale(1, 1)" }}
              width={windowWidth.current}
              height={windowWidth.current}
            />
            <canvas
              ref={canvasRef}
              style={{
                position: "relative",
                zIndex: 99999,
                width: 0,
                height: 0,
              }}
            />
          </Grid>
          <Grid item sm={11} xs={11} md={11}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace" }}
              >
                GPS : {latLong}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "5%"}}>
              <Typography
                sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace" }}
              >
                Address : {address}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "5%"}}>
            <ModelName
              data={result[0]}
              results={result}
              setmodelname={(e) => setModelName(e)}
            />
            </div>
          </Grid>
          <Grid
            container
            sm={11}
            xs={11}
            md={11}
            sx={{ marginTop: "10%", alignItems: "center" }}
          ></Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-evenly",
              flex: 1,
              alignItems: "center",
            }}
          >
            <CleanlinessModel
              data={result[0]}
              results={result}
              setmodelname={(e) => setModelName(e)}
            />
            <Button
              variant="contained"
              sx={{
                width: "30%",
                color: "white",
                alignItems: "center",
              }}
            >
              Submit
            </Button>
            {/* <Button
                  variant="contained"
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize: windowWidth.current / 25,
                  }}
                  onClick={() => navigate("/ocr", { state: { machineID: 2 } })}
                >
                  OCR
                </Button> */}
          </div>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ScanComp;

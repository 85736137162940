import { Typography } from "@mui/material";
import React, { useEffect, useState, useRef, memo } from "react";
import GaugeChart from "react-gauge-chart";

const ModelName = (props) => {
  const data = props.data;
  const label = data?.label;
  const confidence = parseFloat(data?.confidence.toFixed(2));
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  const [labelData, setLabelData] = useState("");

  useEffect(() => {
    if (parseFloat(data?.confidence.toFixed(2)) > 0.9) {
      setLabelData(data.label);
      props.setmodelname(data.label);
    } else {
      setLabelData("No Matches Found");
      props.setmodelname("No Matches Found");
    }
  }, [props.data]);
  return (
    <div>
      <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace" }}
      >
        Assest Name :{" "}
        {labelData == "No Matches Found" ? "No Matches Found" : label}
      </Typography>
    </div>
  );
};
export default memo(ModelName);
